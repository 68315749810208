var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"选择商家","rules":[{ required: true, message: '请选择商家', trigger: 'change' }],"prop":"shopId"}},[_c('v-select',{attrs:{"width":_vm.width,"disabled":_vm.isEdit,"options":_vm.businessOps},model:{value:(_vm.form.shopId),callback:function ($$v) {_vm.$set(_vm.form, "shopId", $$v)},expression:"form.shopId"}})],1),_c('el-form-item',{attrs:{"label":"微信支付商家","rules":[
        {
          required: true,
          message: '请选择微信支付商家',
          trigger: 'change',
        },
      ],"prop":"subMchInfoId"}},[_c('v-select',{attrs:{"disabled":_vm.isEdit,"width":_vm.width,"options":_vm.subMchInfoIdOps},model:{value:(_vm.form.subMchInfoId),callback:function ($$v) {_vm.$set(_vm.form, "subMchInfoId", $$v)},expression:"form.subMchInfoId"}})],1),_c('el-form-item',{attrs:{"label":"商品名称","rules":[
        { required: true, message: '请输入商品名称', trigger: 'blur' },
      ],"prop":"title"}},[_c('v-input',{attrs:{"placeholder":"请输入商品名称","width":_vm.width,"maxlength":50},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"封面图片","rules":[
        { required: true, message: '请上传商品封面图片', trigger: 'blur' },
      ],"prop":"imageUrl"}},[_c('div',{attrs:{"id":"thumbImg"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.imageUrl},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "imageUrl", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "imageUrl", $event)}}})],1)]),_c('el-form-item',{attrs:{"label":"介绍图片","rules":[
        { required: true, message: '请上传商品介绍图片', trigger: 'blur' },
      ],"prop":"pictures"}},[_c('v-upload',{attrs:{"limit":9,"imgUrls":_vm.form.pictures},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "pictures", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "pictures", $event)}}})],1),_c('el-form-item',{attrs:{"label":"商品价格","rules":[
        { required: true, message: '请输入商品价格', trigger: 'blur' },
        {
          message: '必须是数字格式，且精度为小数点后两位',
          pattern: /^[0-9]\d*(.\d{1,2})?$/,
          trigger: 'blur',
        },
      ],"prop":"price"}},[_c('v-input',{attrs:{"placeholder":"请输入商品价格","width":_vm.width},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('el-form-item',{attrs:{"label":"商品排序","rules":[
        { required: true, message: '请输入商品排序值', trigger: 'blur' },
        {
          message: '必须是数字格式',
          pattern: /^[0-9]+$/,
          trigger: 'blur',
        },
      ],"prop":"sortIndex"}},[_c('v-input',{attrs:{"placeholder":"请输入商品排序值","width":_vm.width},model:{value:(_vm.form.sortIndex),callback:function ($$v) {_vm.$set(_vm.form, "sortIndex", $$v)},expression:"form.sortIndex"}})],1),_c('el-form-item',{attrs:{"label":"状态","rules":[{ required: true, message: '请选择状态', trigger: 'blur' }],"prop":"shelfSts"}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.shelfStsOptions},model:{value:(_vm.form.shelfSts),callback:function ($$v) {_vm.$set(_vm.form, "shelfSts", $$v)},expression:"form.shelfSts"}})],1),_c('el-form-item',{attrs:{"label":"商品规格"}},[_c('div',{staticClass:"sku"},[_c('v-button',{attrs:{"size":"medium","type":"success","icon":"el-icon-plus","text":"添加规格"},on:{"click":_vm.addSku}}),_c('div',{staticClass:"sku-table"},[_c('v-table',{ref:"vTable",attrs:{"tableData":_vm.form.skuList,"headers":_vm.headers,"operateColumnWidth":120},scopedSlots:_vm._u([{key:"operateTeSlot",fn:function(scope){return [_c('div',{staticClass:"opera-btn"},[_c('v-button',{attrs:{"type":"text","text":"编辑"},on:{"click":function($event){return _vm.editSku(scope.row.$index)}}}),_c('v-button',{attrs:{"type":"text","text":"删除"},on:{"click":function($event){return _vm.delSku(scope.row.$index)}}})],1)]}}])})],1)],1)]),_c('el-form-item',{attrs:{"label":"详情内容","rules":[
        { required: true, message: '请输入商品内容', trigger: 'blur' },
      ],"prop":"detailContent"}},[_c('div',{attrs:{"id":"editor"}},[_c('v-ueditor',{model:{value:(_vm.form.detailContent),callback:function ($$v) {_vm.$set(_vm.form, "detailContent", $$v)},expression:"form.detailContent"}})],1)])],1),_c('v-dialog',{attrs:{"title":"商品规格信息","width":"500px"},on:{"close":_vm.close,"confirm":_vm.saveSku},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('form-panel',{ref:"form",attrs:{"form":_vm.skuObj,"hasHeader":false,"footerShow":false}},[_c('el-form-item',{attrs:{"label":"规格名称","rules":[
          { required: true, message: '请输入规格名称', trigger: 'blur' },
        ],"prop":"skuName"}},[_c('v-input',{attrs:{"width":_vm.width,"maxlength":20},model:{value:(_vm.skuObj.skuName),callback:function ($$v) {_vm.$set(_vm.skuObj, "skuName", $$v)},expression:"skuObj.skuName"}})],1),_c('el-form-item',{attrs:{"label":"规格图片","rules":[
          { required: true, message: '请上传规格图片', trigger: 'blur' },
        ],"prop":"skuImageUrl"}},[_c('v-upload',{key:`${_vm.curIndex}_upload`,attrs:{"limit":1,"imgUrls":_vm.skuObj.skuImageUrl},on:{"update:imgUrls":function($event){return _vm.$set(_vm.skuObj, "skuImageUrl", $event)},"update:img-urls":function($event){return _vm.$set(_vm.skuObj, "skuImageUrl", $event)}}})],1),_c('el-form-item',{attrs:{"label":"库存","rules":[
          { required: false, message: '请输入库存', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ],"prop":"stock"}},[_c('v-input',{attrs:{"placeholder":"默认为无限制","width":_vm.width},model:{value:(_vm.skuObj.stock),callback:function ($$v) {_vm.$set(_vm.skuObj, "stock", $$v)},expression:"skuObj.stock"}})],1),_c('el-form-item',{attrs:{"label":"排序","rules":[
          { required: true, message: '请输入商品排序值', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ],"prop":"sortIndex"}},[_c('v-input',{attrs:{"width":_vm.width},model:{value:(_vm.skuObj.sortIndex),callback:function ($$v) {_vm.$set(_vm.skuObj, "sortIndex", $$v)},expression:"skuObj.sortIndex"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }