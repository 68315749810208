<template>
  <div class="section">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="选择商家"
        :rules="[{ required: true, message: '请选择商家', trigger: 'change' }]"
        prop="shopId"
      >
        <v-select
          :width="width"
          :disabled="isEdit"
          :options="businessOps"
          v-model="form.shopId"
        />
      </el-form-item>

      <el-form-item
        label="微信支付商家"
        :rules="[
          {
            required: true,
            message: '请选择微信支付商家',
            trigger: 'change',
          },
        ]"
        prop="subMchInfoId"
      >
        <v-select
          :disabled="isEdit"
          :width="width"
          :options="subMchInfoIdOps"
          v-model="form.subMchInfoId"
        />
      </el-form-item>

      <el-form-item
        label="商品名称"
        :rules="[
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ]"
        prop="title"
      >
        <v-input
          placeholder="请输入商品名称"
          v-model="form.title"
          :width="width"
          :maxlength="50"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="封面图片"
        :rules="[
          { required: true, message: '请上传商品封面图片', trigger: 'blur' },
        ]"
        prop="imageUrl"
      >
        <div id="thumbImg">
          <v-upload :limit="1" :imgUrls.sync="form.imageUrl" />
        </div>
      </el-form-item>

      <el-form-item
        label="介绍图片"
        :rules="[
          { required: true, message: '请上传商品介绍图片', trigger: 'blur' },
        ]"
        prop="pictures"
      >
        <v-upload :limit="9" :imgUrls.sync="form.pictures" />
      </el-form-item>

      <el-form-item
        label="商品价格"
        :rules="[
          { required: true, message: '请输入商品价格', trigger: 'blur' },
          {
            message: '必须是数字格式，且精度为小数点后两位',
            pattern: /^[0-9]\d*(.\d{1,2})?$/,
            trigger: 'blur',
          },
        ]"
        prop="price"
      >
        <v-input
          placeholder="请输入商品价格"
          v-model="form.price"
          :width="width"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="商品排序"
        :rules="[
          { required: true, message: '请输入商品排序值', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入商品排序值"
          v-model="form.sortIndex"
          :width="width"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="shelfSts"
      >
        <v-select
          :width="width"
          :options="shelfStsOptions"
          v-model="form.shelfSts"
        />
      </el-form-item>

      <el-form-item label="商品规格">
        <div class="sku">
          <v-button
            size="medium"
            type="success"
            icon="el-icon-plus"
            text="添加规格"
            @click="addSku"
          >
          </v-button>
          <div class="sku-table">
            <v-table
              :tableData="form.skuList"
              ref="vTable"
              :headers="headers"
              :operateColumnWidth="120"
            >
              <template #operateTeSlot="scope">
                <div class="opera-btn">
                  <v-button
                    type="text"
                    @click="editSku(scope.row.$index)"
                    text="编辑"
                  ></v-button>
                  <v-button
                    type="text"
                    @click="delSku(scope.row.$index)"
                    text="删除"
                  ></v-button>
                </div>
              </template>
            </v-table>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="详情内容"
        :rules="[
          { required: true, message: '请输入商品内容', trigger: 'blur' },
        ]"
        prop="detailContent"
      >
        <div id="editor">
          <v-ueditor v-model="form.detailContent" />
        </div>
      </el-form-item>
    </form-panel>

    <v-dialog
      title="商品规格信息"
      v-model="isDialog"
      width="500px"
      @close="close"
      @confirm="saveSku"
    >
      <form-panel
        ref="form"
        :form="skuObj"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          label="规格名称"
          :rules="[
            { required: true, message: '请输入规格名称', trigger: 'blur' },
          ]"
          prop="skuName"
        >
          <v-input :width="width" v-model="skuObj.skuName" :maxlength="20">
          </v-input>
        </el-form-item>
        <el-form-item
          label="规格图片"
          :rules="[
            { required: true, message: '请上传规格图片', trigger: 'blur' },
          ]"
          prop="skuImageUrl"
        >
          <v-upload
            :key="`${curIndex}_upload`"
            :limit="1"
            :imgUrls.sync="skuObj.skuImageUrl"
          />
        </el-form-item>
        <el-form-item
          label="库存"
          :rules="[
            { required: false, message: '请输入库存', trigger: 'blur' },
            {
              message: '必须是数字格式',
              pattern: /^[0-9]+$/,
              trigger: 'blur',
            },
          ]"
          prop="stock"
        >
          <v-input
            placeholder="默认为无限制"
            :width="width"
            v-model="skuObj.stock"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="排序"
          :rules="[
            { required: true, message: '请输入商品排序值', trigger: 'blur' },
            {
              message: '必须是数字格式',
              pattern: /^[0-9]+$/,
              trigger: 'blur',
            },
          ]"
          prop="sortIndex"
        >
          <v-input :width="width" v-model="skuObj.sortIndex"></v-input>
        </el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getQuarterShopListURL,
  getGoodsDetailsURL,
  addOrUpdateQuarterGoodsInfoURL,
  getSubMchIdsURL,
} from "../api.js";
import { setShelfStsOps } from "../map";
import { createImgVNode } from "@/utils/utils.js";

export default {
  data() {
    return {
      width: 250,
      isEdit: false,
      // goodsTypeList,
      businessOps: [],
      // goodsClassifyOps: setGoodsClassifyOps(),
      submitConfig: {
        queryUrl: getGoodsDetailsURL,
        submitUrl: addOrUpdateQuarterGoodsInfoURL,
      },
      shelfStsOptions: setShelfStsOps(),
      subMchInfoIdOps: [],
      form: {
        shopId: "",
        id: "",
        title: "",
        goodsType: 1,
        imageUrl: "",
        pictures: "",
        price: "",
        sortIndex: "",
        shelfSts: 0,
        skuList: [],
        detailContent: "",
      },
      headers: [
        {
          prop: "skuImageUrl",
          label: "图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "skuName",
          label: "规格名称",
        },
        {
          prop: "stock",
          label: "库存",
          formatter: (row, prop) => {
            if (row[prop] !== 0 && row[prop] == "") {
              return "无限量";
            }
            return `${row[prop]}`;
          },
        },
        {
          prop: "sortIndex",
          label: "排序",
        },
      ],
      /** v-dialog */
      curIndex: undefined,
      isDialog: false,
      skuObj: {
        id: "",
        goodsId: "",
        skuName: "",
        skuImageUrl: "",
        sortIndex: 0,
        stock: 0,
      },
    };
  },
  computed: {
    ...mapState("app", ["userInfo"]),
  },
  async mounted() {
    this.getQuarterShopList();
    const { id } = this.$route.query;
    await this.getSubMchIds();
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    }
    this.isEdit = !!id;
    this.$setBreadList(id ? "编辑" : "新增");
    // this.$nextTick(() => {
    //   if (this.$refs.vTable) {
    //     this.$refs.vTable.doLayout();
    //   }
    // });
  },
  methods: {
    close() {
      this.isDialog = false;
    },
    getQuarterShopList() {
      this.businessOps = [];
      this.$axios.get(`${getQuarterShopListURL}`).then((res) => {
        if (res.code === 200) {
          const ops = res.data.map((item) => ({
            ...item,
            label: item.title,
            value: item.id,
          }));

          this.businessOps = ops;
        }
      });
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      // if (this.form.imageUrl) {
      //   this.form.subImg = this.form.imageUrl;
      // }
      // if (this.form.pictures) {
      //   this.form.subPictures = this.form.pictures;
      // }
    },
    submitBefore() {
      // if (!this.form.imageUrl || this.form.imageUrl.length == 0) {
      //   this.$message.error("请上传商品封面图片");
      //   return;
      // }
      // if (!this.form.pictures || this.form.pictures.length == 0) {
      //   this.$message.error("请上传商品介绍图片");
      //   return;
      // }

      // if (
      //   !this.form.pointsPrice ||
      //   !/(^[1-9]\d*$)/.test(this.form.pointsPrice)
      // ) {
      //   this.$message.error("请输入兑换商品所需的正确积分值");
      //   return;
      // }

      if (!this.form.skuList || this.form.skuList.length == 0) {
        this.$message.error("请编辑商品的规格数据");
        return;
      }

      // if (!this.form.adminId) {
      //   this.form.adminId = this.$store.state.app.userInfo.id;
      // }

      return true;
    },
    submitSuccess() {
      return true;
    },
    addSku() {
      this.curIndex = undefined;
      this.skuObj = {
        // id: "",
        goodsId: "",
        skuName: "",
        skuImageUrl: "",
        sortIndex: 0,
        stock: "",
      };
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.isDialog = true;
    },
    editSku(index) {
      console.log(index, "index---");
      this.curIndex = index;
      let data = this.form.skuList[index];
      // this.skuObj = {
      //   skuName: data.skuName,
      //   skuImageUrl: data.skuImageUrl,
      //   sortIndex: data.sortIndex,
      //   stock: data.stock,
      // };
      // if (data.id) {
      //   this.skuObj.id = data.id;
      // }
      // if (data.goodsId) {
      //   this.skuObj.goodsId = data.goodsId;
      // }
      let skuObj = {
        skuName: data.skuName,
        skuImageUrl: data.skuImageUrl,
        sortIndex: data.sortIndex,
        stock: data.stock,
      };
      if (data.id) {
        skuObj.id = data.id;
      }
      if (data.goodsId) {
        skuObj.goodsId = data.goodsId;
      }
      this.skuObj = skuObj;
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.isDialog = true;
    },
    delSku(index) {
      let data = this.form.skuList[index];
      this.$confirm("确定要删除" + data.skuName + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form.skuList.splice(index, 1);
      });
    },
    saveSku() {
      const result = this.$refs.form.validate();
      if (!result) {
        return;
      }
      // this.curIndex 存在则是编辑，反之为新增
      if (this.curIndex !== undefined) {
        this.$set(this.form.skuList, this.curIndex, {
          ...this.form.skuList[this.curIndex],
          ...this.skuObj,
        });
      } else {
        this.form.skuList.push(this.skuObj);
      }
      this.isDialog = false;
    },

    // 获取获取可用的sub_mch_id
    async getSubMchIds() {
      let res = await this.$axios.get(getSubMchIdsURL, {
        params: {
          userId: this.userInfo.userId,
        },
      });
      if (res.code == 200) {
        this.subMchInfoIdOps = res.data.map((v) => ({
          value: v.id,
          label: v.shopName,
          ...v,
        }));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
  .opera-btn {
    display: flex;
    ::v-deep .v-button {
      margin-right: 10px;
    }
  }
  .sku {
    display: flex;
    // flex-direction: column;
    ::v-deep > .v-control {
      margin-right: 40px;
      width: 120px;
    }
    .sku-table {
      width: 1000px;
      height: 500px;
    }
  }
}
</style>
